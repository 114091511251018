import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();

export const AttachPeraWallet = async (address, type) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/PeraWallet?userid=${cookies.get(
        "userid"
      )}&address=${address}&type=${type}`
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AttachPaymentMethod = async (PMID) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/PaymentMethod?userid=${cookies.get("userid")}&pmid=${PMID}`
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const PostWithDrawalData = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/RequestWithdrawal?userid=${cookies.get("userid")}`,
      { ...formData, UserId: cookies.get("userid") }
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const StripePayment = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/StripeCreate`,
      formData
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
export const SingleWithDrawalRequest = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/SingleRequestWithdrawal`,
      { ...formData, userId: cookies.get("userid") }
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const RemoveWallet = async (data) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.delete(
      `WalletAccount/RemoveAccount?id=${data?.id}&type=${data?.type}`
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateStripeUser = async () => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(`WalletAccount/UpdateStripeUser`);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateStripeLLC = async (propertyid) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `WalletAccount/UpdateStripeLLC?propertyid=${propertyid}`
    );

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
