import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../Utils/AxiosInstance";

const initialState = {
  sections: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchSections = createAsyncThunk(
  "Settings/GetSections",
  async () => {
    const response = await axiosInstance.get(`Settings/GetSections`);
    return response.data;
  }
);

const SectionSlice = createSlice({
  name: "sections",
  initialState,
  reducers: {
    removePropertyFormWishList: {
      reducer(state, action) {
        state.WishList = state.WishList.filter((property) => {
          return property.UserWishListId !== action.payload;
        });
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.sections = action.payload.Data;
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getSectionsDetails = (state) => state.sections;

export const { removePropertyFormWishList } = SectionSlice.actions;

export default SectionSlice.reducer;
