import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../Utils/AxiosInstance";

const initialState = {
  expirationData: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const FetchExpirationDateData = createAsyncThunk(
  "admin/FetchExpirationDateData",
  async () => {
    const response = await axiosInstance.get(`Settings/GetSections`);
    return response.data;
  }
);

const ExpirationDateSlice = createSlice({
  name: "ExpirationDate",
  initialState,
  reducers: {
    resetExpirationDateSlice: (state) => {
      state.expirationData = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchExpirationDateData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchExpirationDateData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.expirationData = action.payload.Data;
      })
      .addCase(FetchExpirationDateData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getSectionsDetails = (state) => state.ExpirationDate;
export const { resetExpirationDateSlice } = ExpirationDateSlice.actions;
export default ExpirationDateSlice.reducer;
