import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const TopBarContext = createContext();

const TopBarContextProvider = (props) => {
  const [topBarData, settopBarData] = useState({
    ImageLink: "",
    UserName: "",
  });

  return (
    <TopBarContext.Provider value={{ topBarData, settopBarData }}>
      {props.children}
    </TopBarContext.Provider>
  );
};

export default TopBarContextProvider;

TopBarContextProvider.propTypes = {
  children: PropTypes.any,
};
