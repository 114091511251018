import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { store } from "./Redux/store";
import { Provider } from "react-redux";
import TopBarContextProvider from "./Context/TopBarContext/TopBarContext";
import SnackBarContextProvider from "./Context/SnackBarContent/SnackBarContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Provider store={store}>
      <SnackBarContextProvider>
        <TopBarContextProvider>
          <App />
        </TopBarContextProvider>
      </SnackBarContextProvider>
    </Provider>
  </BrowserRouter>
  // </React.StrictMode>
);

reportWebVitals();
