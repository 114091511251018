import { configureStore } from "@reduxjs/toolkit";
import PropertySlice from "./PropertySlice/PropertySlice";
import GetAllQuestionSlice from "./SignUp/GetAllQuestionSlice";
import UserPersonalInfoSlice from "./User/UserPersonalInfoSlice/UserPersonalInfoSlice";
import UserProfileQuestionnaireSlice from "./User/UserProfileSlice/UserProfileQuestionnaireSlice";
import UserPropertySlice from "./User/UserPropertySlice/UserPropertySlice";
import FeedbackSlice from "./Admin/FeedBack/FeedbackSlice";
import TestimonialsSlice from "./Admin/Testiminials/TestimonialsSlice";
import UserSlice from "./Admin/User/UserSlice";
import DocumentsSlice from "./Documents/DocumentsSlice";
import PropertyFileTypeSlice from "./Admin/Properties/PropertyFileTypeSlice";
import BlogSlice from "./Blogs/BlogSlice";
import ModuleSlice from "./Admin/Modules/ModuleSlice";
import Admins from "./Admin/Admins/Admins";
import ReferAndEarnSlice from "./RefferAndEarn/ReferAndEarnSlice";
import WalletSlice from "./User/WalletSlice/WalletSlice";
import Properties from "./Admin/Properties/Properties";
import TokensSlice from "./Tokens/TokensSlice";
import SectionSlice from "./Sections/SectionSlice";
import WithdrawalSlice from "./User/WithDrawalSlice/WithdrawalSlice";
import TransactionHistorySlice from "./TransactionHistory/TransactionHistorySlice";
import LogedInUserSlice from "./Admin/LogedInUserRoles/LogedInUserRoles";
import WithDrawRequestSlice from "./User/WithDrawalSlice/WithDrawRequestSlice";
import ExpirationDateSlice from "./ExpirationDate/ExpirationDateSlice";
import UpcomingExpiriesSlice from "./Admin/UpcomingExpiries/UpcomingExpiriesSlice";

export const store = configureStore({
  reducer: {
    personalInfo: UserPersonalInfoSlice,
    properties: PropertySlice,
    userProperties: UserPropertySlice,
    SignUpGetAllQuestion: GetAllQuestionSlice,
    UserProfileQuestionnaire: UserProfileQuestionnaireSlice,
    Testimonials: TestimonialsSlice,
    DocumentsFields: DocumentsSlice,
    allBlogs: BlogSlice,
    wallets: WalletSlice,
    tokens: TokensSlice,
    sections: SectionSlice,
    withdraw: WithdrawalSlice,
    transactionHistory: TransactionHistorySlice,
    ///////admin reducers

    FeedBacks: FeedbackSlice,
    Users: UserSlice,
    propertyFileType: PropertyFileTypeSlice,
    Modules: ModuleSlice,
    allAdmins: Admins,
    referAndEarn: ReferAndEarnSlice,
    AdminProperties: Properties,
    Roles: LogedInUserSlice,
    withdrawRequest: WithDrawRequestSlice,
    ExpirationDate: ExpirationDateSlice,
    UpcomingExpiries: UpcomingExpiriesSlice,
  },
});
