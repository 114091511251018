import { Paper, Skeleton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import React from "react";
import theme from "../../theme";

const useStyle = makeStyles(() => {
  return {
    container: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "2px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    leftSideContainer: {
      textAlign: "right",
      display: "flex",
      alignItems: "end",
      flexDirection: "column",

      padding: "20px",
      gap: "10px",
      background: "rgba(200,200,200,0.1)",
      width: "260px",
      [theme.breakpoints.down('md')]:{
        width:'100%'
      }
    },
    SkeletonContainer: {
      textAlign: "right",
      display: "flex",
      alignItems: "end",
      flexDirection: "column",
    },
  };
});

const WalletSkeletonCard = () => {
  const { container, leftSideContainer, SkeletonContainer } = useStyle();

  return (
    <Paper elevation={3} className={container}>
      <Box sx={{ padding: "20px", width: "100%" }}>
        <Skeleton width={100} height={50} />
        <Skeleton width={250} height={30} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "end",
          flexDirection: "column",
        }}
      >
        <Box className={leftSideContainer}>
          <Box className={SkeletonContainer}>
            <Skeleton width={120} height={30} />
            <Skeleton width={50} height={30} />
          </Box>
          <Box className={SkeletonContainer}>
            <Skeleton width={150} height={30} />
            <Skeleton width={50} height={30} />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default WalletSkeletonCard;
