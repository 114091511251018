import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  propertyFileType: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllFileTypes = createAsyncThunk(
  "Property/GetAllFileTypes",
  async () => {
    const response = await axiosInstance.get(`Property/GetAllFileTypes`);
    return response.data;
  }
);

const PropertFileTypeSlice = createSlice({
  name: "propertyFileType",
  initialState,
  reducers: {
    resetFileType: (state) => {
      state.propertyFileType = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllFileTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFileTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.propertyFileType = action.payload.Data;
      })
      .addCase(fetchAllFileTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllPropertyFileTypes = (state) => state.propertyFileType;
export const { resetFileType } = PropertFileTypeSlice.actions;
export default PropertFileTypeSlice.reducer;
