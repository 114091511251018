import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const BlogContext = createContext();

const BlogContextProvider = (props) => {
  const [BlogData, setBlogData] = useState({
    BlogTitle: "",
    AuthorName: "",
    Category: "",
    BlogCategory: "",
    ThumbnailLink: "",
    Thumbnail: "",
    AuthorImage: "",
    AuthorImageLink: "",
    AuthorimgFile: "",
    BlogContent: "",
  });
  const [activeStep, setActiveStep] = useState(0);

  return (
    <BlogContext.Provider
      value={{ BlogData, setBlogData, activeStep, setActiveStep }}
    >
      {props.children}
    </BlogContext.Provider>
  );
};

export default BlogContextProvider;

BlogContextProvider.propTypes = {
  children: PropTypes.any,
};
