import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  userProperties: [],
  propertyDetails: null,
  WishList: [],
  amenities: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchUserProperties = createAsyncThunk(
  "Property/GetUserProperty",
  async () => {
    const response = await axiosInstance.get(
      `Property/GetUserProperty?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);
export const fetchUserSingleProperty = createAsyncThunk(
  "Property/GetSingleAddress",
  async (AddressId) => {
    const response = await axiosInstance.get(
      `Property/GetSingleAddress?addressId=${AddressId}`
    );
    return response.data;
  }
);

export const fetchWishList = createAsyncThunk(
  "Property/GetUserWishList",
  async (filterQuery) => {
    const response = await axiosInstance.get(
      `Property/GetUserWishList?userId=${cookies.get("userid")}&propertytype=${
        filterQuery?.propertytype
      }&sort=${filterQuery?.sort}`
    );
    return response.data;
  }
);

export const fetchAllAmenities = createAsyncThunk(
  "Property/GetAllAmenity",
  async () => {
    const response = await axiosInstance.get(`Property/GetAllAmenity`);
    return response.data;
  }
);

const PropertySlice = createSlice({
  name: "userProperties",
  initialState,
  reducers: {
    removePropertyFormWishList: {
      reducer(state, action) {
        state.WishList = state.WishList.filter((property) => {
          return property.UserWishListId !== action.payload;
        });
      },
    },
    removeSinglePropertyDetails: {
      reducer(state) {
        state.propertyDetails = null;
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserProperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.userProperties = action.payload.Data;
      })
      .addCase(fetchUserProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchWishList.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWishList.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.WishList = action.payload.Data;
      })
      .addCase(fetchWishList.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllAmenities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAmenities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.amenities = action.payload.Data;
      })
      .addCase(fetchAllAmenities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUserSingleProperty.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserSingleProperty.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.propertyDetails = action.payload.Data;
      })
      .addCase(fetchUserSingleProperty.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserProperties = (state) => state.userProperties;

export const { removePropertyFormWishList, removeSinglePropertyDetails } =
  PropertySlice.actions;

export default PropertySlice.reducer;
