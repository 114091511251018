import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";

const cookies = new Cookies();

const initialState = {
  referAndEarn: {},
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchReferandEarnPopulate = createAsyncThunk(
  "RefferAndEarn/PopulateReffereAndEarnDashboard",
  async () => {
    const response = await axiosInstance.get(
      `RefferAndEarn/PopulateReffereAndEarnDashboard?userId=${cookies.get(
        "userid"
      )}`
    );
    return response.data;
  }
);

const ReferAndEarnSlice = createSlice({
  name: "referAndEarn",
  initialState,
  reducers: {
    removePropertyFormWishList: {
      reducer(state, action) {
        state.WishList = state.WishList.filter((property) => {
          return property.UserWishListId !== action.payload;
        });
      },
    },
    resetReferAndEarn: (state) => {
      state.referAndEarn = {};
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReferandEarnPopulate.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchReferandEarnPopulate.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.referAndEarn = action.payload.Data;
      })
      .addCase(fetchReferandEarnPopulate.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getReferAndEarnDetails = (state) => state.referAndEarn;

export const { removePropertyFormWishList, resetReferAndEarn } =
  ReferAndEarnSlice.actions;

export default ReferAndEarnSlice.reducer;
