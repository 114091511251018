
import PropTypes from "prop-types";
import {  LinearProgress , Box } from "@mui/material";


const  LinearProgressWithLabel = ({ value }) =>{
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{
            width: "100%",
            height: "7px",
            mr: 1,
            backgroundColor: (theme) => theme.palette.greenShades.g94,
            borderRadius: (theme) => theme.borderRadius.All,
          }}
        >
          <LinearProgress
            variant="determinate"
            value={value}
            sx={{
              borderRadius: (theme) => theme.borderRadius.All,
              height: "7px",
            }}
          />
        </Box>
        {/* <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            value
          )}%`}</Typography>
        </Box> */}
      </Box>
    );
  }

  export default LinearProgressWithLabel;
  LinearProgressWithLabel.propTypes = {
    value: PropTypes.any,
  };
  