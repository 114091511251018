import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Utils/AxiosInstance";

const initialState = {
  SignUpGetAllQuestion: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchSignUpGetAllQuestion = createAsyncThunk(
  "Signup/GetAllQuestion",
  async () => {
    const response = await axiosInstance.get(`Signup/GetAllQuestion`);

    return response.data;
  }
);

const SignUpGetAllQuestionSlice = createSlice({
  name: "SignUpGetAllQuestion",
  initialState,
  reducers: {
    PropertyAdded: {
      reducer(state, action) {
        state.SignUpGetAllQuestion.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSignUpGetAllQuestion.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSignUpGetAllQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.SignUpGetAllQuestion = action.payload.Data;
      })
      .addCase(fetchSignUpGetAllQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getSignUpAllQuestionDetails = (state) =>
  state.SignUpGetAllQuestion;

export const { PropertyAdded } = SignUpGetAllQuestionSlice.actions;

export default SignUpGetAllQuestionSlice.reducer;
