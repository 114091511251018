import axios from "axios";
import Cookies from "universal-cookie";
let urlType = "test";
const cookies = new Cookies();
// let token = cookies.get("token");
export const webSiteUrl = "https://realroi.aofenggsys.us";
// export const webSiteUrl = "localhost:3000";
const BaseUrl =
  urlType === "test"
     ?   "https://realroiapi.azurewebsites.net/"
      // ? "https://localhost:7283/"
      //"https://realroitest.azurewebsites.net/"
    : urlType === "local"
    ? ""
    : "";
const axiosInstance = axios.create({
  baseURL: BaseUrl,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${cookies.get("token")}`,
  },
});

// some way of changing it
// export const setToken = (newLocale) => {
//   token = newLocale;
// };

// register a synchronous request interceptor
axiosInstance.interceptors.request.use(
  (config) => ({
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${cookies.get("token")}`,
    },
  }),
  null,
  { synchronous: true }
);

export default axiosInstance;
