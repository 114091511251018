import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();
const initialState = {
  DocumentsFields: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchDocumentsFields = createAsyncThunk(
  "UserProfile/GetAllUserDocTypes",
  async () => {
    const response = await axiosInstance.get(
      `UserProfile/GetAllUserDocTypes?userid=${cookies.get("userid")}`
    );
    return response.data;
  }
);

const DocumentsSlice = createSlice({
  name: "DocumentsFields",
  initialState,
  reducers: {
    updateDocument: {
      reducer(state, action) {
        state.DocumentsFields.find((doc) => {
          if (doc.UserDocumetnTypeIds === action.payload.UserDocumetnTypeIds) {
            doc.UserDocuments = action.payload.UserDocuments;
          }
        });
      },
    },
    resetDocuments: (state) => {
      state.DocumentsFields = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDocumentsFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchDocumentsFields.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.DocumentsFields = action.payload.Data;
      })
      .addCase(fetchDocumentsFields.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getDocumnetsFields = (state) => state.DocumentsFields;
export const { updateDocument, resetDocuments } = DocumentsSlice.actions;

export default DocumentsSlice.reducer;
