import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  Modules: [],
  Roles: [],
  Role: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllModules = createAsyncThunk(
  "/AdminRole/GetModules",
  async () => {
    const response = await axiosInstance.get(`/AdminRole/GetModules`);
    return response.data;
  }
);

export const fetchAllRoles = createAsyncThunk(
  "/AdminRole/GetRoles",
  async () => {
    const response = await axiosInstance.get(`/AdminRole/GetRoles`);
    return response.data;
  }
);

export const fetchSingleRole = createAsyncThunk(
  "AdminRole/GetRoleByID",
  async (id) => {
    const response = await axiosInstance.get(`AdminRole/GetRoleByID?id=${id}`);
    return response.data;
  }
);

const ModuleSlice = createSlice({
  name: "Modules",
  initialState,
  reducers: {
    updateAddRole: {
      reducer(state, action) {
        let newRoles = [];
        let matched = false;
        state.Roles.forEach((role) => {
          if (role?.RolesId === action.payload.RolesId) {
            role = action.payload;
            matched = true;
          }
          newRoles.push(role);
        });

        if (matched) {
          state.Roles = newRoles;
        } else {
          state.Roles.push(action.payload);
        }
      },
    },
    resetModule: (state) => {
      state.Modules = [];
      state.Roles = [];
      state.Role = null;
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllModules.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllModules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Modules = action.payload.Data;
      })
      .addCase(fetchAllModules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Roles = action.payload.Data;
      })
      .addCase(fetchAllRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSingleRole.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleRole.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Role = action.payload.Data;
      })
      .addCase(fetchSingleRole.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllModulesDetails = (state) => state.Modules;

export const { updateAddRole, resetModule } = ModuleSlice.actions;

export default ModuleSlice.reducer;
