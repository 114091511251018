import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  Users: [],
  blockedUsers: [],
  singelUserDetail: {},
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllUsers = createAsyncThunk("/User/GetAllUsers", async () => {
  const response = await axiosInstance.get(
    `/User/GetAllUsers?userId=${cookies.get("userid")}`
  );
  return response.data;
});
export const fetchBlockedUsers = createAsyncThunk(
  "User/GetBlockedUsers",
  async () => {
    const response = await axiosInstance.get(`User/GetBlockedUsers`);
    return response.data;
  }
);

export const fetchSingelUserDetail = createAsyncThunk(
  "User/UsersDetail",
  async (userId) => {
    const response = await axiosInstance.get(`User/UsersDetail?Id=${userId}`);
    return response.data;
  }
);

const UserSlice = createSlice({
  name: "Users",
  initialState,
  reducers: {
    UserAdded: {
      reducer(state, action) {
        state.Users.push(action.payload);
      },
    },
    removeUserDetail: {
      reducer(state) {
        state.singelUserDetail = {};
      },
    },
    resetUserDetails: (state) => {
      state.Users = [];
      state.blockedUsers = [];
      state.singelUserDetail = {};
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Users = action.payload.Data;
      })
      .addCase(fetchAllUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchBlockedUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlockedUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.blockedUsers = action.payload.Data;
      })
      .addCase(fetchBlockedUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSingelUserDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingelUserDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singelUserDetail = action.payload.Data;
      })
      .addCase(fetchSingelUserDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllUsersDetails = (state) => state.Users;

export const { UserAdded, removeUserDetail, resetUserDetails } = UserSlice.actions;

export default UserSlice.reducer;
