import { createContext, useState } from "react";
import PropTypes from "prop-types";

export const MyPropertyContext = createContext();

const MyPropertyContextProvider = (props) => {
  const [PropertyDetails, setPropertyDetails] = useState({
    BlogTitle: "BlogTitle",
    AuthorName: "AuthorName",
    Category: "Category",
  });

  return (
    <MyPropertyContext.Provider value={{ PropertyDetails, setPropertyDetails }}>
      {props.children}
    </MyPropertyContext.Provider>
  );
};

export default MyPropertyContextProvider;

MyPropertyContextProvider.propTypes = {
  children: PropTypes.any,
};
