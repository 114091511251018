import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  withdraw: [],
  withdrawalRequests: [],
  UserRentData: [],
  AvailableRent: 0,
  PendingWithdrawl: 0,
  RentEarned: 0,
  WithdrawlRent: 0,
  ClearedAmount: 0,
  PendingAmount: 0,
  RequestedAmount: 0,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchWithDrawalData = createAsyncThunk(
  "WalletAccount/GetWithdrawalRequests",
  async () => {
    const response = await axiosInstance.get(
      `WalletAccount/GetWithdrawalRequests?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

export const fetchUserWithDrawalRequest = createAsyncThunk(
  "WalletAccount/GetWithdrawalByUser",
  async () => {
    const response = await axiosInstance.get(
      `WalletAccount/GetWithdrawalByUser`
    );
    return response.data;
  }
);
export const fetchUserWithDrawalData = createAsyncThunk(
  "WalletAccount/GetRentSummariesByUser",
  async () => {
    const response = await axiosInstance.get(
      `WalletAccount/GetRentSummariesByUser?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

const WithdrawSlice = createSlice({
  name: "withdraw",
  initialState,
  reducers: {
    resetWithdraw: (state) => {
      state.withdraw = [];
      state.withdrawalRequests = [];
      state.UserRentData = [];
      state.AvailableRent = 0;
      state.PendingWithdrawl = 0;
      state.RentEarned = 0;
      state.WithdrawlRent = 0;
      state.ClearedAmount = 0;
      state.PendingAmount = 0;
      state.RequestedAmount = 0;
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWithDrawalData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchWithDrawalData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.withdraw = action.payload.Data;
        state.ClearedAmount = action.payload.ClearedAmount;
        state.PendingAmount = action.payload.PendingAmount;
        state.RequestedAmount = action.payload.RequestedAmount;
      })
      .addCase(fetchWithDrawalData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUserWithDrawalRequest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserWithDrawalRequest.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.withdrawalRequests = action.payload.Data;
      })
      .addCase(fetchUserWithDrawalRequest.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchUserWithDrawalData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserWithDrawalData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserRentData = action.payload.Data;
        state.AvailableRent = action.payload.AvailableRent;
        state.PendingWithdrawl = action.payload.PendingWithdrawl;
        state.RentEarned = action.payload.RentEarned;
        state.WithdrawlRent = action.payload.WithdrawlRent;
      })
      .addCase(fetchUserWithDrawalData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserWithdrawData = (state) => state.withdraw;
export const { resetWithdraw } = WithdrawSlice.actions;
export default WithdrawSlice.reducer;
