import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../Utils/AxiosInstance";

const initialState = {
  allBlogs: [],
  PublishedBlogs: [],
  blogCategories: [],
  singleBlog: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllBlogs = createAsyncThunk(
  "Blog/GetAllBlogs",
  async (id) => {
    const response = await axiosInstance.get(
      `${id ? `Blog/GetAllBlogs?categoryId=${id}` : "Blog/GetAllBlogs"}`
    );
    return response.data;
  }
);

export const fetchPublishedBlogs = createAsyncThunk(
  "Blog/GetPublishedBlogs",
  async (id) => {
    const response = await axiosInstance.get(
      `${
        id
          ? `Blog/GetPublishedBlogs?categoryId=${id}`
          : "Blog/GetPublishedBlogs"
      }`
    );
    return response.data;
  }
);

export const fetchBlogCategories = createAsyncThunk(
  "Blog/BlogCategories",
  async () => {
    const response = await axiosInstance.get(`Blog/BlogCategories`);
    return response.data;
  }
);

export const fetchSingleBlogDetails = createAsyncThunk(
  "Blog/BlogsDetail",
  async (id) => {
    const response = await axiosInstance.get(`Blog/BlogsDetail?id=${id}`);
    return response.data;
  }
);

const BlogSlice = createSlice({
  name: "allBlogs",
  initialState,
  reducers: {
    BlogAdded: {
      reducer(state, action) {
        state.allBlogs.push(action.payload);
      },
    },
    removeSingleBlog: {
      reducer(state) {
        state.singleBlog = null;
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllBlogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllBlogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allBlogs = action.payload.Data;
      })
      .addCase(fetchAllBlogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPublishedBlogs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPublishedBlogs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.PublishedBlogs = action.payload.Data;
      })
      .addCase(fetchPublishedBlogs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchBlogCategories.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchBlogCategories.fulfilled, (state, action) => {
        // state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.blogCategories = action.payload.Data;
      })
      .addCase(fetchBlogCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchSingleBlogDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingleBlogDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singleBlog = action.payload.Data.Data;
      })
      .addCase(fetchSingleBlogDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllBlogsDetails = (state) => state.allBlogs;

export const { BlogAdded, removeSingleBlog } = BlogSlice.actions;

export default BlogSlice.reducer;
