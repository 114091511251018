import React, { lazy } from "react";
const WithdrawalRequests = lazy(() =>
  import("../Pages/Admin/WithDrawalRequests/WithdrawalRequests")
);
const RequestedWithdrawal = lazy(() =>
  import("../Pages/RequestedWithdrawals/RequestedWithdrawal")
);
const VerifyEmail = lazy(() => import("../Pages/VerifyEmail/VerifyEmail"));
import theme from "../theme";
import WithDrawalAccounts from "../Pages/WithdrawalAccounts/WithdrawalAccounts";
const UpcommingExpires = lazy(() =>
  import("../Pages/UpcommingExpires/UpcommingExpires")
);

const UserProfileDashboardLayout = lazy(() =>
  import("../Layouts/UserProfileDashboardLayout/UserProfileDashboardLayout")
);
const AdminProfileDashboardLayout = lazy(() =>
  import("../Layouts/AdminProfileDashboardLayout/AdminProfileDashboardLayout")
);
const MainLayout = lazy(() => import("../Layouts/MainLayout/MainLayout"));
const UserDashboardLayout = lazy(() =>
  import("../Layouts/UserDashboardLayout/UserDashboardLayout")
);
const AdminDashboardLayout = lazy(() =>
  import("../Layouts/AdminDashboardLayout/AdminDashboardLayout")
);

const ForgotPassword = lazy(() =>
  import("../Pages/ForgotPassword/ForgotPassword")
);
const RentWithdraw = lazy(() => import("../Pages/RentWithdraw/RentWithdraw"));

const TwoFactorLogin = lazy(() =>
  import("../Pages/TwoFactorLogin/TwoFactorLogin")
);

const ChangePassword = lazy(() =>
  import("../Pages/ChangePassword/ChangePassword")
);

const Affiliates = lazy(() => import("../Pages/Affiliates/Affiliates"));

const AccreditedInvestorInformation = lazy(() =>
  import(
    "../Components/SignUp/AccreditedInvestorInformation/AccreditedInvestorInformation"
  )
);
const ContactInformation = lazy(() =>
  import("../Components/SignUp/ContactInformation/ContactInformation")
);
const PassBaseInformation = lazy(() =>
  import("../Components/SignUp/PassBaseInformation/PassBaseInformation")
);
const PersonalInformation = lazy(() =>
  import("../Components/SignUp/PersonalInformation/PersonalInformation")
);
const SocialSecurityInformation = lazy(() =>
  import(
    "../Components/SignUp/SocialSecurityInformation/SocialSecurityInformation"
  )
);
const TwoFactorAuthentication = lazy(() =>
  import("../Components/SignUp/TwoFactorAuthentication/TwoFactorAuthentication")
);
const ValidationInformation = lazy(() =>
  import("../Components/SignUp/ValidationInformation/ValidationInformation")
);

const GeneralDisclaimer = lazy(() =>
  import("../Pages/GeneralDisclaimer/GeneralDisclaimer")
);
const TermsOfService = lazy(() =>
  import("../Pages/TermsOfService/TermsOfService")
);
const PrivacyPolicy = lazy(() =>
  import("../Pages/PrivacyPolicy/PrivacyPolicy")
);
const Team = lazy(() => import("../Pages/Team/Team"));

const AccreditedQuestionnaire = lazy(() =>
  import(
    "../Components/Settings/AccreditedQuestionnaire/AccreditedQuestionnaire"
  )
);
const BlogPreview = lazy(() =>
  import("../Pages/Admin/BlogPreview/BlogPreview")
);
const Wallets = lazy(() => import("../Pages/Wallets/Wallets"));
const ContactUs = lazy(() => import("../Components/ContactUs/ContactUs"));
const Documents = lazy(() =>
  import("../Components/Settings/Documents/Documents")
);
const Feedback = lazy(() => import("../Components/Settings/Feedback/Feedback"));
const MyAccount = lazy(() =>
  import("../Components/Settings/MyAccount/MyAccount")
);
const Profile = lazy(() => import("../Components/Settings/Profile/Profile"));
const UnderConstruction = lazy(() =>
  import("../Components/UnderConstruction/UnderConstruction")
);
const AboutUs = lazy(() => import("../Pages/AboutUs/AboutUs"));
const AddListing = lazy(() => import("../Pages/AddListing/AddListing"));
const AddAdmin = lazy(() => import("../Pages/Admin/Admins/AddAdmin"));
const AllAdmins = lazy(() => import("../Pages/Admin/Admins/AllAdmins"));
const BlockedUsers = lazy(() =>
  import("../Pages/Admin/BlockedUser/BlockedUsers")
);
const AddBlog = lazy(() => import("../Pages/Admin/Blogs/AddBlog"));
const AllBlogs = lazy(() => import("../Pages/Admin/Blogs/AllBlogs"));
const RoleManagement = lazy(() =>
  import("../Pages/Admin/RoleManagement/RoleManagement")
);
const AllTestimonials = lazy(() =>
  import("../Pages/Admin/Testimonials/AllTestimonials")
);
const AllUsers = lazy(() => import("../Pages/Admin/Users/AllUsers"));
const UserDetails = lazy(() => import("../Pages/Admin/Users/UserDetails"));
const AssetsOverview = lazy(() =>
  import("../Pages/AssetsOverview/AssetsOverview")
);
const BlogDetails = lazy(() => import("../Pages/BlogDetails/BlogDetails"));
const Blogs = lazy(() => import("../Pages/Blogs/Blogs"));
const Favourites = lazy(() => import("../Pages/Favourites/Favourites"));
const LandingPage = lazy(() => import("../Pages/LandingPage/LandingPage"));
const Login = lazy(() => import("../Pages/Login/Login"));
const DashboardProperties = lazy(() =>
  import("../Pages/Marketplace/DashboardProperties")
);
const MyListing = lazy(() => import("../Pages/MyListing/MyListing"));
const MyTokens = lazy(() => import("../Pages/MyTokens/MyTokens"));
const PropertyDetails = lazy(() =>
  import("../Pages/PropertyDetails/PropertyDetails")
);
const ReferAndEarn = lazy(() => import("../Pages/ReferAndEarn/ReferAndEarn"));
const SignUp = lazy(() => import("../Pages/SignUp/SignUp"));
const TransactionHistory = lazy(() =>
  import("../Pages/TransactionHistory/TransactionHistory")
);

/////////////////////// open paths
const loginSignUpPaths = [
  { path: "/login", component: <Login /> },

  {
    path: "/signup/validationinfo",
    component: <SignUp activePage={<ValidationInformation />} step={1} />,
  },
  {
    path: "/signup/personalinfo",
    component: <SignUp activePage={<PersonalInformation />} step={2} />,
  },
  {
    path: "/signup/contactinfo",
    component: <SignUp activePage={<ContactInformation />} step={3} />,
  },
  {
    path: "/signup/ssninfo",
    component: <SignUp activePage={<SocialSecurityInformation />} step={4} />,
  },
  {
    path: "/signup/passbaseinfo",
    component: <SignUp activePage={<PassBaseInformation />} step={5} />,
  },
  {
    path: "/signup/accquestionnair",
    component: (
      <SignUp activePage={<AccreditedInvestorInformation />} step={6} />
    ),
  },
  {
    path: "/signup/twofactorauth",
    component: <SignUp activePage={<TwoFactorAuthentication />} step={7} />,
  },
];
const openPaths = [
  { path: "*", component: <UnderConstruction /> },
  { path: "/verifyingemail", component: <VerifyEmail /> },

  { path: "/", component: <MainLayout activePage={<LandingPage />} /> },

  { path: "/processes", component: <MainLayout activePage={<AboutUs />} /> },
  { path: "/contactUs", component: <MainLayout activePage={<ContactUs />} /> },

  {
    path: "/blogs",
    component: <MainLayout activePage={<Blogs singleBlogPath={"/blogs/"} />} />,
  },
  {
    path: "/blogs/:id",
    component: (
      <MainLayout
        activePage={<BlogDetails isAdmin={false} color={"white"} />}
      />
    ),
  },
  { path: "/teams", component: <MainLayout activePage={<Team />} /> },
  {
    path: "/privacypolicy",
    component: <MainLayout activePage={<PrivacyPolicy />} />,
  },
  {
    path: "/termsofservice",
    component: <MainLayout activePage={<TermsOfService />} />,
  },
  {
    path: "/generaldisclaimer",
    component: <MainLayout activePage={<GeneralDisclaimer />} />,
  },
  {
    path: "/affiliates",
    component: <MainLayout activePage={<Affiliates />} />,
  },
  {
    path: "/resetpassword",
    component: <ChangePassword />,
  },
  {
    path: "/forgotpassword",
    component: <ForgotPassword />,
  },
  {
    path: "/twofactorlogin",
    component: <TwoFactorLogin />,
  },
  {
    path: "/marketplace/property/:id",
    component: (
      <MainLayout
        activePage={<PropertyDetails isAdmin={false} isLoggedIn={false} />}
      />
    ),
  },
];
export const basicPaths = [...loginSignUpPaths, ...openPaths];

//////////////////////// user paths
const useDashBoardPaths = [
  {
    path: "/dashboard",
    component: (
      <UserDashboardLayout
        activePage={<AssetsOverview />}
        headerTitle={"Dashboard"}
      />
    ),
  },
  {
    path: "/user/marketplace",
    component: (
      <UserDashboardLayout
        activePage={
          <DashboardProperties
            newPropertyPath={"/listproperty/form"}
            path="/user/marketplace/details"
            isAdmin={false}
            color={theme.palette.black.main}
          />
        }
        headerTitle={"Marketplace"}
      />
    ),
  },
  {
    path: "/user/marketplace/details/:id",
    component: (
      <UserDashboardLayout
        activePage={<PropertyDetails isLoggedIn={true} isAdmin={false} />}
        headerTitle={"Property Details"}
      />
    ),
  },
  {
    path: "/user/rentwithdraw",
    component: (
      <UserDashboardLayout
        activePage={<RentWithdraw isLoggedIn={true} isAdmin={false} />}
        headerTitle={"Rent Withdraw"}
      />
    ),
  },
  {
    path: "/user/withdrawalrequests",
    component: (
      <UserDashboardLayout
        activePage={<RequestedWithdrawal isLoggedIn={true} isAdmin={false} />}
        headerTitle={"Withdrawal Requests"}
      />
    ),
  },

  {
    path: "/listproperty/form",
    component: (
      <UserDashboardLayout
        activePage={<AddListing backNavigationPath={-1} isAdmin={false} />}
        headerTitle={"Add Property"}
      />
    ),
  },
  {
    path: "/payments",
    component: (
      <UserDashboardLayout
        activePage={<TransactionHistory />}
        headerTitle={"Payments History"}
      />
    ),
  },
  {
    path: "/user/mylistings",
    component: (
      <UserDashboardLayout
        activePage={
          <MyListing
            newPropertyPath={"/listproperty/form"}
            allproperties={false}
            path="/user/mylistings"
            isAdmin={false}
          />
        }
        headerTitle={"My Listing"}
      />
    ),
  },
  {
    path: "/user/wallets",
    component: (
      <UserDashboardLayout activePage={<Wallets />} headerTitle={"Wallets"} />
    ),
  },
  {
    path: "/user/withdrawalaccounts",
    component: (
      <UserDashboardLayout
        activePage={<WithDrawalAccounts />}
        headerTitle={"Withdrawal Accounts"}
      />
    ),
  },
  {
    path: "/user/favourites",
    component: (
      <UserDashboardLayout
        activePage={<Favourites path="/user/marketplace/details" />}
        headerTitle={"Favourites"}
      />
    ),
  },

  {
    path: "/user/tokens",
    component: (
      <UserDashboardLayout
        activePage={<MyTokens />}
        headerTitle={"My Tokens"}
      />
    ),
  },

  {
    path: "/user/referandearn",
    component: (
      <UserDashboardLayout
        activePage={<ReferAndEarn />}
        headerTitle={"Refer and Earn"}
      />
    ),
  },
];
const userSettingsPaths = [
  {
    path: "/setting/profile",
    component: <UserProfileDashboardLayout activePage={<Profile />} />,
  },
  {
    path: "/setting/myaccount",
    component: <UserProfileDashboardLayout activePage={<MyAccount />} />,
  },
  {
    path: "/setting/documents",
    component: <UserProfileDashboardLayout activePage={<Documents />} />,
  },
  {
    path: "/setting/feedback",
    component: <UserProfileDashboardLayout activePage={<Feedback />} />,
  },
  {
    path: "/setting/accreditedquestionnaire",
    component: (
      <UserProfileDashboardLayout activePage={<AccreditedQuestionnaire />} />
    ),
  },
];
export const userPaths = [...useDashBoardPaths, ...userSettingsPaths];

//////////////////////admin paths
export const adminSettingsPaths = [
  {
    path: "/admin/profile",
    component: <AdminProfileDashboardLayout activePage={<Profile />} />,
  },
  {
    path: "/admin/myaccount",
    component: <AdminProfileDashboardLayout activePage={<MyAccount />} />,
  },
  // {
  //   path: "/admin/documents",
  //   component: <AdminProfileDashboardLayout activePage={<Documents />} />,
  // },
];
const adminDashboardPaths = [
  // {
  //   path: "/admin/dashboard",
  //   component: (
  //     <AdminDashboardLayout
  //       activePage={<AssetsOverview />}
  //       headerTitle={"Dashboard"}
  //       isAdmin={true}
  //     />
  //   ),
  // },
  {
    path: "/admin/listproperty/form",
    component: (
      <AdminDashboardLayout
        activePage={<AddListing backNavigationPath={-1} isAdmin={true} />}
        headerTitle={"Property"}
        screen={["Properties-Add"]}
      />
    ),
  },
  {
    path: "/admin/favourites",
    component: (
      <AdminDashboardLayout
        activePage={<Favourites path="/admin/properties/details" />}
        headerTitle={"Favourites"}
        screen={["Favourites-View"]}
      />
    ),
  },
  {
    path: "/admin/marketplace",
    component: (
      <AdminDashboardLayout
        activePage={
          <DashboardProperties
            newPropertyPath={"/admin/listproperty/form"}
            path="/admin/properties/details"
            color={theme.palette.black.main}
          />
        }
        headerTitle={"Marketplace"}
        screen={["MarketPlace-View"]}
      />
    ),
  },
  {
    path: "/admin/properties",
    component: (
      <AdminDashboardLayout
        activePage={
          <MyListing
            newPropertyPath={"/admin/listproperty/form"}
            allproperties={true}
            path="/admin/properties"
            isAdmin={true}
          />
        }
        headerTitle={"All Properties"}
        screen={["Properties-View"]}
      />
    ),
  },
  {
    path: "/admin/mylistings",
    component: (
      <AdminDashboardLayout
        activePage={
          <MyListing
            newPropertyPath={"/admin/listproperty/form"}
            allproperties={false}
            path="/admin/mylistings"
            isAdmin={true}
          />
        }
        headerTitle={"My Listing"}
        screen={["MyListing-View"]}
      />
    ),
  },
  {
    path: "/admin/properties/details/:id",
    component: (
      <AdminDashboardLayout
        activePage={<PropertyDetails isAdmin={true} isLoggedIn={true} />}
        headerTitle={"Property Details"}
        screen={["MarketPlace-View"]}
      />
    ),
  },

  // {
  //   path: "/admin/wallets",
  //   screen: ["Marketplace", "MyListing"],
  //   component: (
  //     <AdminDashboardLayout activePage={<Wallets />} headerTitle={"Wallets"} />
  //   ),
  // },
  // {
  //   path: "/admin/wallets",
  //   screen: ["Marketplace", "MyListing"],
  //   component: (
  //     <AdminDashboardLayout activePage={<Wallets />} headerTitle={"Wallets"} />
  //   ),
  // },
  {
    path: "/admin/withdrawalrequests",
    component: (
      <AdminDashboardLayout
        activePage={<WithdrawalRequests />}
        headerTitle={"Withdrawal Requests"}
        screen={["WithdrawalRequests-View"]}
      />
    ),
  },

  {
    path: "/admin/alladmins",
    component: (
      <AdminDashboardLayout
        activePage={<AllAdmins />}
        headerTitle={"Admins"}
        screen={["Admins-View"]}
      />
    ),
  },
  {
    path: "/admin/addadmin",
    component: (
      <AdminDashboardLayout
        activePage={<AddAdmin />}
        headerTitle={"Add Admin"}
        screen={["Admins-Add", "Admins-Edit"]}
      />
    ),
  },
  {
    path: "/admin/allusers",
    component: (
      <AdminDashboardLayout
        activePage={<AllUsers />}
        headerTitle={"All Users"}
        screen={["Users-View"]}
      />
    ),
  },
  {
    path: "/admin/blockedusers",
    component: (
      <AdminDashboardLayout
        activePage={<BlockedUsers />}
        headerTitle={"Blocked Users"}
        screen={["Users-View"]}
      />
    ),
  },
  {
    path: "/admin/user/:id",
    component: (
      <AdminDashboardLayout
        activePage={<UserDetails />}
        headerTitle={""}
        screen={["Users-View"]}
      />
    ),
  },
  {
    path: "/admin/rolemanagement",
    component: (
      <AdminDashboardLayout
        activePage={<RoleManagement />}
        headerTitle={"Roles"}
        screen={["RoleManagement-View"]}
      />
    ),
  },
  {
    path: "/admin/testimonials",
    component: (
      <AdminDashboardLayout
        activePage={<AllTestimonials />}
        headerTitle={"Testimonials"}
        screen={["Testimonials-View"]}
      />
    ),
  },
  {
    path: "/admin/addblog",
    component: (
      <AdminDashboardLayout
        activePage={<AddBlog />}
        headerTitle={"Add Blog"}
        screen={["Blogs-Add", "Blogs-Edit"]}
      />
    ),
  },
  {
    path: "/admin/blog/preview",
    component: (
      <BlogPreview headerTitle={"Testimonials"} screen={["Blogs-View"]} />
    ),
  },
  {
    path: "/admin/allblogs",
    component: (
      <AdminDashboardLayout
        activePage={<AllBlogs singleBlogPath={"/admin/blog/"} />}
        headerTitle={"All Blogs"}
        screen={["Blogs-View"]}
      />
    ),
  },
  {
    path: "/admin/blog/:id",
    component: (
      <AdminDashboardLayout
        activePage={
          <BlogDetails isAdmin={true} color={"black"} screen={["Blogs-View"]} />
        }
      />
    ),
  },
  {
    path: "/admin/upcomingexpires",
    component: (
      <AdminDashboardLayout
        activePage={<UpcommingExpires />}
        headerTitle={"Upcoming Expires"}
        screen={["Properties-Edit"]}
      />
    ),
  },
];
export const adminPaths = [...adminDashboardPaths];
