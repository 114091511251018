import "./App.css";
import React, { Suspense, useContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import {
  adminPaths,
  adminSettingsPaths,
  basicPaths,
  userPaths,
} from "./ListOfPaths/ListOfPaths";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import CssBaseline from "@mui/material/CssBaseline";
import FallBackLoading from "./Components/FallbackLoading/FallBackLoading";
import BlogContextProvider from "./Context/BlogContext/BlogContext";
import SnackBar from "./Components/SnackBar/SnackBar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MyPropertyContextProvider from "./Context/MyPropertyDetailContext/MyPropertyDetailContext";
import Cookies from "universal-cookie";
import DashboardProperties from "./Pages/Marketplace/DashboardProperties";
import { TopBarContext } from "./Context/TopBarContext/TopBarContext";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLogedInUserRoles,
  getRolesDetails,
} from "./Redux/Admin/LogedInUserRoles/LogedInUserRoles";
import MainLayout from "./Layouts/MainLayout/MainLayout";
import AdminProtectedRoutes from "./ProtectedRoutes/AdminProtectedRoutes";

const cookies = new Cookies();

function App() {
  const stripePromise = loadStripe(
    "pk_test_51Lz1ehFL2sEip4Zias7aOCtqHGY3X6LMueiYpztbamn2wudhIjRoYi53yHRWcBvGtzCWlHKMDhInLwLK6OWnFYQR00mMwLdvgQ"
  );
  const dispatch = useDispatch();

  const { Roles } = useSelector(getRolesDetails);

  const { settopBarData } = useContext(TopBarContext);
  useEffect(() => {
    window.Intercom("boot", {
      app_id: "k5cr1d4s",
    });
    dispatch(fetchLogedInUserRoles());
  }, []);

  const [openPaths, setopenPaths] = useState([
    {
      path: "/marketplace",
      component: (
        <MainLayout
          activePage={
            <DashboardProperties
              newPropertyPath={
                cookies.get("userType") === undefined ||
                cookies.get("userType") === null
                  ? "/listproperty/form"
                  : cookies.get("userType") !== "user"
                  ? "/admin/listproperty/form"
                  : "/listproperty/form"
              }
              path={
                cookies.get("userType") === undefined ||
                cookies.get("userType") === null
                  ? "/marketplace/property"
                  : cookies.get("userType") !== "user"
                  ? "/admin/properties/details"
                  : cookies.get("userType") === "user"
                  ? "/user/marketplace/details"
                  : "/marketplace/property"
              }
              color={theme.palette.white.main}
            />
          }
        />
      ),
    },
  ]);
  useEffect(() => {
    setopenPaths([
      {
        path: "/marketplace",
        component: (
          <MainLayout
            activePage={
              <DashboardProperties
                newPropertyPath={
                  cookies.get("userType") === undefined ||
                  cookies.get("userType") === null
                    ? "/listproperty/form"
                    : cookies.get("userType") !== "user"
                    ? "/admin/listproperty/form"
                    : "/listproperty/form"
                }
                path={
                  cookies.get("userType") === undefined ||
                  cookies.get("userType") === null
                    ? "/marketplace/property"
                    : cookies.get("userType") !== "user"
                    ? "/admin/properties/details"
                    : cookies.get("userType") === "user"
                    ? "/user/marketplace/details"
                    : "/marketplace/property"
                }
                backgroundColor={theme.palette.white.main}
                color={theme.palette.white.main}
              />
            }
          />
        ),
      },
    ]);

    settopBarData({
      UserName: cookies.get("userName"),
      ImageLink: cookies.get("imageUrl"),
    });
  }, [cookies]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Elements stripe={stripePromise}>
        <BlogContextProvider>
          <MyPropertyContextProvider>
            <div className="App">
              <Suspense fallback={<FallBackLoading />}>
                <Routes>
                  {basicPaths?.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        exact
                        path={route.path}
                        element={route.component}
                      />
                    );
                  })}
                  {openPaths?.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        exact
                        path={route.path}
                        element={route.component}
                      />
                    );
                  })}
                  {userPaths?.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        exact
                        path={route.path}
                        element={route.component}
                      />
                    );
                  })}
                  {adminPaths?.map((route) => {
                    let matched = route?.screen?.some((scree) => {
                      if (Roles[scree]) {
                        return true;
                      }
                    });

                    return (
                      <Route
                        key={route.path}
                        exact
                        path={route.path}
                        element={
                          <AdminProtectedRoutes matched={matched}>
                            {route.component}
                          </AdminProtectedRoutes>
                        }
                      />
                    );
                  })}
                  {adminSettingsPaths?.map((route) => {
                    return (
                      <Route
                        key={route.path}
                        exact
                        path={route.path}
                        element={route.component}
                      />
                    );
                  })}
                </Routes>
                <SnackBar />
              </Suspense>
            </div>
          </MyPropertyContextProvider>
        </BlogContextProvider>
      </Elements>
    </ThemeProvider>
  );
}

export default App;

`1. Add form to add LLC bank details of the property,  on the "admin" screen of the update property. The bank account shall be attached using plaid 
3. For rent withdrawal functionality explore the solution to provide the ability to withdraw rent from the LLC bank account of the property using plaid only with no involvement of stripe
4. serverless functions or a similar solution need to be put in place which can trigger the process of sending back the payments to the users, in case the desired tokens are not sold until the closing date of the smart contract.
5. No payments received from the users through credit/ debit cards shall reside in the real-roi stripe account Alex wants the money transferred to the LLC bank account as soon as it is received.`;
