import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";
const cookies = new Cookies();

export const AddProperty = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(`Property/AddProperty`, {
      ...formData,
      UserId: cookies.get("userid"),
    });
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
      sessionStorage.setItem(
        "propertyAddress",
        response.data.Data?.AddressName
      );
      sessionStorage.setItem(
        "AddressId",
        response.data.Data?.PropertyAddressId
      );
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateProperty = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.put(`Property/UpdateAddress`, {
      ...formData,
      UserId: cookies.get("userid"),
    });
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
      sessionStorage.setItem(
        "propertyAddress",
        response.data.Data?.AddressName
      );
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddListingInfo = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `Property/AddPropertyListing`,
      formData
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const UpdateListingInfo = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.put(
      `Property/UpdateListing`,
      formData
    );
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddNewAminity = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(`Property/AddAmenity`, formData);

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddToWishList = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `Property/AddUserWishList`,
      formData
    );

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const GetSinglePropertyDetail = async (AddressId) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.get(
      `Property/GetSingleAddress?addressId=${AddressId}`
    );

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddPropertyImages = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `Property/UpdatePropertyImages`,
      formData
    );

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const AddPropertyInEx = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `Property/AddPropertyInEx`,
      formData
    );

    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const BuyProperty = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(`Property/CheckOut`, formData);
    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const BuyPropertyConfirmation = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.post(
      `Property/CheckOutComplete`,
      formData
    );
    snackBarData = {
      type: response.data.IsSuccess ? "success" : "error",
      message: response.data.Message,
      openToast: true,
    };

    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const RemoveWishList = async (arr) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.delete("Property/RemoveWishList", {
      data: arr,
    });
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};

export const RemoveSinglePropertyFromWishList = async (formData) => {
  let snackBarData = {};
  try {
    const response = await axiosInstance.delete(`Property/RemoveWishList`, {
      data: formData,
    });
    if (!response.data.IsSuccess) {
      snackBarData = {
        type: "error",
        message: response.data.Message,
        openToast: true,
      };
    } else if (response.data.IsSuccess) {
      snackBarData = {
        type: "success",
        message: response.data.Message,
        openToast: true,
      };
    }
    return {
      ...response,
      snackBarData,
    };
  } catch (error) {
    snackBarData = {
      type: "error",
      message: error?.message,
      openToast: true,
    };
    return { ...error?.response, snackBarData };
  }
};
