import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  FeedBacks: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllFeedback = createAsyncThunk(
  "Feedback/GetAllFeedbacks",
  async () => {
    const response = await axiosInstance.get(
      `Feedback/GetAllFeedbacks?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

const FeedbackSlice = createSlice({
  name: "FeedBacks",
  initialState,
  reducers: {
    FeedbackAdded: {
      reducer(state, action) {
        state.FeedBacks.push(action.payload);
      },
    },
    resetFeedback: (state) => {
      state.FeedBacks = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllFeedback.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllFeedback.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.FeedBacks = action.payload.Data;
      })
      .addCase(fetchAllFeedback.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllFeedbackDetails = (state) => state.FeedBacks;

export const { FeedbackAdded, resetFeedback } = FeedbackSlice.actions;

export default FeedbackSlice.reducer;
