import {
  Box,
  CircularProgress,
  Modal,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import {} from "react-scroll";
import PropTypes from "prop-types";
import { useContext, useState } from "react";
import { AddToWishList } from "../../Apis/Property/PropertyApis";
import Cookies from "universal-cookie";
import { SnackBarContext } from "../../Context/SnackBarContent/SnackBarContext";
import { useDispatch } from "react-redux";
import { fetchAllProperties } from "../../Redux/PropertySlice/PropertySlice";
import { RemoveSinglePropertyFromWishList } from "../../Apis/Property/PropertyApis";
import { removePropertyFormWishList } from "../../Redux/User/UserPropertySlice/UserPropertySlice";
import theme from "../../theme";
const ConfirmationFavModal = ({
  showModal,
  setshowModal,
  SelectedProperty,
  PaginationData,
}) => {
  const cookies = new Cookies();
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);
  const dispatch = useDispatch();
  const handelAddFvrtProperty = async (data) => {
    setloading(true);
    const response = await AddToWishList(data);

    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data.IsSuccess) {
      dispatch(fetchAllProperties(PaginationData));
      setshowModal(false);
    }
  };
  const handelRemoveFvrtProperty = async (data) => {
    setloading(true);

    const response = await RemoveSinglePropertyFromWishList(data);

    setsnackBarData(response.snackBarData);
    setloading(false);
    if (response.data.IsSuccess) {
      dispatch(removePropertyFormWishList(data[0]));
      setshowModal(false);
    }
  };
  return (
    <Modal
      sx={{
        height: "100%" /* equals max image height */,
        width: "100%",
        // whiteSpace: "nowrap",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
      open={showModal}
      onClose={() => {
        setshowModal(false);
      }}
    >
      <Paper
        sx={{
          minWidth: "300px",
          height: "200px",
          padding: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          overflowWrap: "anywhere",
        }}
      >
        <Typography
          sx={{
            ...theme.typography.h6Bold,
            width: "100%",
            textAlign: "left",
          }}
        >
          Are you sure you want to&nbsp;
          {SelectedProperty?.ActionType}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: "15px",
          }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <Button
                onClick={() => {
                  setshowModal(false);
                }}
                varient="outlined"
                sx={{
                  backgroundColor: (theme) => theme.palette.white.main,
                  color: (theme) => theme.palette.primary.main,
                  border: (theme) => ` 1px solid ${theme.palette.primary.main}`,

                  borderRadius: (theme) => theme.borderRadius.All,
                  height: "fit-content !important",
                }}
              >
                No
              </Button>
              <Button
                onClick={() => {
                  SelectedProperty?.isFavourite
                    ? handelRemoveFvrtProperty(SelectedProperty?.UserWishListId)
                    : handelAddFvrtProperty({
                        userId: cookies.get("userid"),
                        propertyId: SelectedProperty?.propertyId,
                        isFavourite: SelectedProperty?.isFavourite,
                      });
                }}
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.white.main,
                  borderRadius: (theme) => theme.borderRadius.All,
                  height: "fit-content !important",
                }}
              >
                Yes
              </Button>
            </>
          )}
        </Box>
      </Paper>
    </Modal>
  );
};
export default ConfirmationFavModal;
ConfirmationFavModal.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  loading: PropTypes.bool,
  SelectedProperty: PropTypes.any,
  PaginationData: PropTypes.any,
};
