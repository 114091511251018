import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  Testimonials: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllTestimonials = createAsyncThunk(
  "Feedback/Published",
  async () => {
    const response = await axiosInstance.get(`Feedback/Published`);
    return response.data;
  }
);

const TestimonialsSlice = createSlice({
  name: "Testimonials",
  initialState,
  reducers: {
    resetTestimonial: (state) => {
      state.Testimonials = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllTestimonials.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllTestimonials.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Testimonials = action.payload.Data;
      })
      .addCase(fetchAllTestimonials.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllTestimonialsDetails = (state) => state.Testimonials;
export const { resetTestimonial } = TestimonialsSlice.actions;

export default TestimonialsSlice.reducer;
