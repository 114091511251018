import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../Utils/AxiosInstance";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const initialState = {
  properties: [],
  PageNumber: 1,
  TotalPages: 0,
  PageSize: 10,
  proprtyType: "",
  location: "",
  sorting: "",
  minValue: "",
  maxValue: "",
  propertyTypes: [],
  amenities: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllProperties = createAsyncThunk(
  "Property/FilterProperties",
  async (data) => {
    const response = await axiosInstance.get(
      `Property/FilterProperties?PageNumber=${data?.PageNumber}&PageSize=${
        data?.PageSize
      }&proprtyType=${data?.proprtyType}&location=${data?.location}&sorting=${
        data?.sorting
      }&minValue=${data?.minValue}&maxValue=${data?.maxValue}&UserId=${
        cookies.get("userid") || ""
      }`
    );
    return response.data;
  }
);

export const fetchPropertyTypes = createAsyncThunk(
  "Property/GetPropertyType",
  async () => {
    const response = await axiosInstance.get(`Property/GetPropertyType`);
    return response.data;
  }
);

export const fetchAllAmenities = createAsyncThunk(
  "Property/GetAllAmenity",
  async () => {
    const response = await axiosInstance.get(`Property/GetAllAmenity`);
    return response.data;
  }
);

const PropertySlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    PropertyAdded: {
      reducer(state, action) {
        state.properties.push(action.payload);
      },
    },
    NewAminityAdded: {
      reducer(state, action) {
        state.amenities.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllProperties.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllProperties.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.properties = action.payload.Data;
        state.PageNumber = action.payload.PageNumber;
        state.PageSize = action.payload.PageSize;
        state.TotalPages = action.payload.TotalPages;
        state.TotalRecords = action.payload.TotalRecords;
        state.location = action.payload.Filter?.location || "";
        state.sorting = action.payload.Filter?.sorting || "";
        state.minValue = action.payload.Filter?.minValue || "";
        state.maxValue = action.payload.Filter?.maxValue || "";
        state.proprtyType = action.payload.Filter?.proprtyType || "";
      })
      .addCase(fetchAllProperties.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchPropertyTypes.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPropertyTypes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.propertyTypes = action.payload.Data;
      })
      .addCase(fetchPropertyTypes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchAllAmenities.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAmenities.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.amenities = action.payload.Data;
      })
      .addCase(fetchAllAmenities.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getPropertiesDetails = (state) => state.properties;

export const { PropertyAdded, NewAminityAdded } = PropertySlice.actions;

export default PropertySlice.reducer;
