import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  wallets: [],
  linkedWallet: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchUserWallets = createAsyncThunk(
  "WalletAccount/Wallets",
  async () => {
    const response = await axiosInstance.get(
      `WalletAccount/Wallets?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

export const fetchlinkedWallets = createAsyncThunk(
  "WalletAccount/linked",
  async () => {
    const response = await axiosInstance.get(
      `/WalletAccount/GetLinkedAccounts`
    );
    return response.data;
  }
);

const WalletSlice = createSlice({
  name: "wallets",
  initialState,
  reducers: {
    removePropertyFormWishList: {
      reducer(state, action) {
        state.WishList = state.WishList.filter((property) => {
          return property.UserWishListId !== action.payload;
        });
      },
    },
    resetWallet: (state) => {
      state.wallets = [];
      state.linkedWallet = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserWallets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserWallets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.wallets = action.payload.Data;
      })
      .addCase(fetchUserWallets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchlinkedWallets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchlinkedWallets.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.linkedWallet = action.payload.Data;
      })
      .addCase(fetchlinkedWallets.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserWallets = (state) => state.wallets;

export const { removePropertyFormWishList, resetWallet } = WalletSlice.actions;

export default WalletSlice.reducer;
