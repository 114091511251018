import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  UserProfileQuestionnaire: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchUserProfileQuestionnaire = createAsyncThunk(
  "Signup/GetUserQuestionnaire",
  async (userId) => {
    const response = await axiosInstance.get(
      `Signup/GetUserQuestionnaire?userId=${userId}`
    );
    return response.data;
  }
);

const UserProfileQuestionnaireSlice = createSlice({
  name: "UserProfileQuestionnaire",
  initialState,
  reducers: {
    QuestionnaireAdded: {
      reducer(state, action) {
        state.UserProfileQuestionnaire.push(action.payload);
      },
    },
    resetQuestionnaire: (state) => {
      state.UserProfileQuestionnaire = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserProfileQuestionnaire.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserProfileQuestionnaire.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UserProfileQuestionnaire = action.payload.Data;
      })
      .addCase(fetchUserProfileQuestionnaire.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserProfileQuestionnaireDetails = (state) =>
  state.UserProfileQuestionnaire;

export const { QuestionnaireAdded, resetQuestionnaire } =
  UserProfileQuestionnaireSlice.actions;

export default UserProfileQuestionnaireSlice.reducer;
