import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  allAdmins: [],
  singelAdminDetail: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllAdmins = createAsyncThunk(
  "AdminRole/GetAllAdmins",
  async () => {
    const response = await axiosInstance.get(`AdminRole/GetAllAdmins`);
    return response.data;
  }
);

export const fetchSingelAdminDetail = createAsyncThunk(
  "AdminRole/GetAdminById",
  async (id) => {
    const response = await axiosInstance.get(`AdminRole/GetAdminById?id=${id}`);
    return response.data;
  }
);

const AdminSlice = createSlice({
  name: "allAdmins",
  initialState,
  reducers: {
    AdminAdded: {
      reducer(state, action) {
        state.allAdmins.push(action.payload);
      },
    },
    resetAdmins: (state) => {
      state.allAdmins = [];
      state.singelAdminDetail = null;
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllAdmins.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllAdmins.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.allAdmins = action.payload.Data;
      })
      .addCase(fetchAllAdmins.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchSingelAdminDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSingelAdminDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.singelAdminDetail = action.payload.Data;
      })
      .addCase(fetchSingelAdminDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllAdminsDetails = (state) => state.allAdmins;

export const { AdminAdded, resetAdmins } = AdminSlice.actions;

export default AdminSlice.reducer;
