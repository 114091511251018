// import React from "react";
import PropTypes from "prop-types";
// import { Navigate } from "react-router-dom";

const AdminProtectedRoutes = ({ children }) => {
  // if (!matched) {
  //   return <Navigate to={"/"} replace />;
  // }

  return children;
};

export default AdminProtectedRoutes;
AdminProtectedRoutes.propTypes = {
  matched: PropTypes.any,
  children: PropTypes.any.isRequired,
};
