import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
const cookies = new Cookies();

const initialState = {
  Roles: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchLogedInUserRoles = createAsyncThunk(
  "Auth/GetRoles",
  async () => {
    const response = await axiosInstance.get(
      `Auth/GetRoles?userId=${cookies.get("userid")}`
    );

    return response.data;
  }
);

const LogedInUserSlice = createSlice({
  name: "Roles",
  initialState,
  reducers: {
    GetRoles: {
      reducer(state, action) {
        state.Roles = action.payload;
      },
    },
    resetRoles: (state) => {
      state.Roles = {};
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchLogedInUserRoles.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchLogedInUserRoles.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.Roles = action.payload.Roles;
      })
      .addCase(fetchLogedInUserRoles.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getRolesDetails = (state) => state.Roles;

export const { GetRoles, resetRoles } = LogedInUserSlice.actions;

export default LogedInUserSlice.reducer;
