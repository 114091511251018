import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  AdminProperties: [],
  PageNumber: 1,
  TotalPages: 0,
  PageSize: 10,
  proprtyType: "",
  location: "",
  sorting: "",
  minValue: "",
  status: "",
  maxValue: "",
  isSuccess: false,
  message: null,
  APIstatus: "idle",
  error: null,
};

export const fetchAllPropertiesForAdmin = createAsyncThunk(
  "/AdminProperty/FilterProperties",
  async (data) => {
    const response = await axiosInstance.get(
      `/AdminProperty/FilterProperties?PageNumber=${data?.PageNumber}&status=${data?.status}&PageSize=${data?.PageSize}&proprtyType=${data?.proprtyType}&location=${data?.location}&sorting=${data?.sorting}&minValue=${data?.minValue}&maxValue=${data?.maxValue}`
    );
    return response.data;
  }
);

const AdminPropertySlice = createSlice({
  name: "AdminProperties",
  initialState,
  reducers: {
    UserAdded: {
      reducer(state, action) {
        state.AdminProperties.push(action.payload);
      },
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllPropertiesForAdmin.pending, (state) => {
        state.APIstatus = "loading";
      })
      .addCase(fetchAllPropertiesForAdmin.fulfilled, (state, action) => {
        state.APIstatus = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.AdminProperties = action.payload.Data;
        state.PageNumber = action.payload.PageNumber;
        state.PageSize = action.payload.PageSize;
        state.TotalPages = action.payload.TotalPages;
        state.TotalRecords = action.payload.TotalRecords;
      })
      .addCase(fetchAllPropertiesForAdmin.rejected, (state, action) => {
        state.APIstatus = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllPropertiesForAdminDetails = (state) => state.AdminProperties;

export const { UserAdded } = AdminPropertySlice.actions;

export default AdminPropertySlice.reducer;
