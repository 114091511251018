import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  UpcomingExpiries: [],
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchAllUpcomingExpiries = createAsyncThunk(
  "/Property/GetExpiredProperties",
  async () => {
    const response = await axiosInstance.get(`/Property/GetExpiredProperties`);
    return response.data;
  }
);

const UpcomingExpiriesSlice = createSlice({
  name: "UpcomingExpiries",
  initialState,
  reducers: {
    resetUpcomingExpiries: (state) => {
      state.UpcomingExpiries = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAllUpcomingExpiries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAllUpcomingExpiries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.UpcomingExpiries = action.payload.Data;
      })
      .addCase(fetchAllUpcomingExpiries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getAllupcomingExpiriesDetails = (state) => state.UpcomingExpiries;
export const { resetUpcomingExpiries } = UpcomingExpiriesSlice.actions;

export default UpcomingExpiriesSlice.reducer;
