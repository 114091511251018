import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import axiosInstance from "../../../Utils/AxiosInstance";

const initialState = {
  personalInfo: null,
  accountInfo: null,
  IsSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchUserDetail = createAsyncThunk(
  "Signup/GetUserDetail",
  async (userId) => {
    const response = await axiosInstance.get(
      `Signup/GetUserDetail?userId=${userId}`
    );

    return response.data;
  }
);
export const fetchUserAccountDetail = createAsyncThunk(
  "UserProfile/GetAccountDetails",
  async (userId) => {
    const response = await axiosInstance.get(
      `UserProfile/GetAccountDetails?userId=${userId}`
    );

    return response.data;
  }
);

const UserPersonalInfoSlice = createSlice({
  name: "personalInfo",
  initialState,
  reducers: {
    updateAccountInfo: {
      reducer(state, action) {
        state.accountInfo = action.payload;
      },
    },
    updatePersonalInfo: {
      reducer(state, action) {
        state.personalInfo = action.payload;
      },
    },
    resetUserInfo: (state) => {
      state.personalInfo = null;
      state.accountInfo = null;
      state.IsSuccess = false;
      state.message = null;
      state.status = "idle"; //'idle' | 'loading' | 'succeeded' | 'failed'
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.IsSuccess = action.payload.IsSuccess;
        state.personalInfo = action.payload.Data;
      })
      .addCase(fetchUserDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })

      .addCase(fetchUserAccountDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserAccountDetail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.IsSuccess = action.payload.IsSuccess;
        state.accountInfo = action.payload.Data;
      })
      .addCase(fetchUserAccountDetail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getPersonalDetails = (state) => state.personalInfo;

export const { updateAccountInfo, updatePersonalInfo, resetUserInfo } =
  UserPersonalInfoSlice.actions;

export default UserPersonalInfoSlice.reducer;
