import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import Cookies from "universal-cookie";
import axiosInstance from "../../../Utils/AxiosInstance";
// const cookies = new Cookies();

const initialState = {
  withdrawRequestData: null,
  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const FetchWithdrawRequests = createAsyncThunk(
  "Fetch/WithdrawRequest",
  async () => {
    const response = await axiosInstance.get(
      `/WalletAccount/GetWithdrawalByUser`
    );
    return response.data;
  }
);

const WithDrawRequestSlice = createSlice({
  name: "withdrawRequest",
  initialState,
  reducers: {
    resetWithdrawlRequest: (state) => {
      state.withdrawRequestData = null;
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(FetchWithdrawRequests.pending, (state) => {
        state.status = "loading";
      })
      .addCase(FetchWithdrawRequests.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.withdrawRequestData = action.payload;
      })
      .addCase(FetchWithdrawRequests.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getWithdrawRequestData = (state) => state.withdrawRequest;
export const { resetWithdrawlRequest } = WithDrawRequestSlice.actions;
export default WithDrawRequestSlice.reducer;
