import {
  Typography,
  Box,
  Paper,
  Modal,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
  CircularProgress,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchlinkedWallets,
  getUserWallets,
} from "../../Redux/User/WalletSlice/WalletSlice";
import noDataImage from "../../Assets/nodatafound.png";
import WalletSkeletonCard from "../../Components/SkeletonCard/WalletSkeletonCard";
import VerifiedIcon from "@mui/icons-material/Verified";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { UpdateStripeUser } from "../../Apis/WalletAccount/WalletAccountApis";
import { SnackBarContext } from "../../Context/SnackBarContent/SnackBarContext";
const WithDrawalAccounts = () => {
  const { linkedWallet, status } = useSelector(getUserWallets);
  const dispatch = useDispatch();
  const [ShowModal, setShowModal] = useState(false);
  const [WithdrawalWallets, setWithdrawalWallets] = useState([]);
  const [loading, setloading] = useState(false);
  const { setsnackBarData } = useContext(SnackBarContext);

  console.log(WithdrawalWallets);
  useEffect(() => {
    dispatch(fetchlinkedWallets());
  }, [dispatch]);

  useEffect(() => {
    if (linkedWallet) {
      setWithdrawalWallets(linkedWallet);
    }
  }, [linkedWallet]);

  const handelUploadClick = async () => {
    setloading(true);

    const res = await UpdateStripeUser();
    setloading(false);
    setsnackBarData(res?.snackBarData);

    if (res.data.IsSuccess) {
      dispatch(fetchlinkedWallets());
      setShowModal(false);
    }
  };

  return (
    <Box
      sx={{
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ textAlign: "left" }}>
          <Typography sx={(theme) => theme.typography.h5}>
            WithDrawal Accounts
          </Typography>

          {/* <Typography>
            This is where you can add, edit and remove payment methods and
            crypto wallets from your account{" "}
          </Typography> */}
        </Box>
        <Typography
          sx={{
            color: (theme) =>
              linkedWallet?.transfers !== "active"
                ? theme?.palette.error.main
                : theme?.palette.primary.main,
            display: "flex",
            gap: "5px",
            alignItems: "center",
          }}
        >
          {linkedWallet?.transfers === "active" ? "InActive" : "Active"}

          {linkedWallet?.transfers === "active" ? (
            <LiveHelpIcon
              sx={{
                color: "#000000",
                fontSize: "18px",
                cursor: "pointer",
              }}
              onClick={() => setShowModal(true)}
            />
          ) : null}
        </Typography>
      </Box>
      {(linkedWallet?.accounts?.length < 1 ||
        linkedWallet?.accounts === null) &&
      status === "succeeded" ? (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box component={"img"} src={noDataImage} sx={{ width: "70%" }} />
        </Box>
      ) : status === "loading" ? (
        Array(3)
          .fill("")
          .map((m, i) => {
            return <WalletSkeletonCard key={i} />;
          })
      ) : (
        <>
          {/* <Plaid Bank Account /> */}
          {linkedWallet?.accounts?.data.map((bank, index) => {
            return (
              <Paper
                key={index}
                elevation="3"
                sx={{
                  textAlign: "left",
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: { md: "row", sm: "column", xs: "column" },
                }}
              >
                <Box
                  sx={{
                    backgroundColor: (theme) => theme.palette.white.main,
                    width: "100%",
                    padding: "20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "30px" }}
                  ></Box>
                  <Box sx={{ lineHeight: "3" }}>
                    <Typography
                      sx={{
                        fontSize: "24px",
                        color: (theme) => theme.palette.primary.main,
                        lineHeight: "1",
                        textTransform: "uppercase",
                      }}
                    >
                      {bank?.bank_name}
                    </Typography>
                    <Typography
                      sx={{
                        width: "300px",
                        textOverflow: "ellipsis",
                        lineClamp: 2,
                        overflow: "hidden",
                        // textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        mt: 3,
                      }}
                    >
                      ********{bank?.last4}{" "}
                      {bank?.status === "verified" ? (
                        <VerifiedIcon fontSize="small" color="primary" />
                      ) : null}
                    </Typography>
                    <Typography
                      sx={{
                        width: "300px",
                        textOverflow: "ellipsis",
                        lineClamp: 2,
                        overflow: "hidden",
                        // textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Routing Num {bank?.routing_number}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "20px" }}
                  ></Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "start",
                    gap: "20px",
                    padding: "20px",
                    backgroundColor: (theme) => theme.palette.white.main,
                  }}
                ></Box>
              </Paper>
            );
          })}
        </>
      )}
      <AddStripeAccWallet
        showModal={ShowModal}
        setshowModal={setShowModal}
        errors={linkedWallet?.err}
        handelUploadClick={handelUploadClick}
        loading={loading}
      />
    </Box>
  );
};

export default WithDrawalAccounts;

const AddStripeAccWallet = ({
  showModal,
  setshowModal,
  errors,
  handelUploadClick,
  loading,
}) => {
  console.log(errors);
  return (
    <Modal
      sx={{
        height: "100%" /* equals max image height */,
        width: "100%",
        // whiteSpace: "nowrap",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px",
      }}
      open={showModal}
      onClose={() => {
        setshowModal(false);
      }}
    >
      <Paper
        sx={{
          minWidth: "300px",
          maxWidth: "500px",
          height: "200px",
          padding: "25px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            fontFamily: "SofiaProBlack",
          }}
        >
          Errors
        </Typography>
        <List dense={true}>
          {errors?.map((err, idx) => {
            return (
              <ListItem key={idx} disableGutters={true} dense={true}>
                <ListItemIcon>
                  <WarningAmberIcon
                    sx={{
                      color: (theme) => theme.palette.error.main,
                    }}
                  />
                </ListItemIcon>
                <ListItemText primary={err?.reason} />
              </ListItem>
            );
          })}
        </List>

        <Typography
          sx={{
            textAlign: "left",
          }}
        >
          If you have updated the requrenments upload the latest account details
          by clicking this Button &nbsp;
          {loading ? (
            <CircularProgress />
          ) : (
            <Button
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                color: (theme) => theme.palette.white.main,
                textTransform: "capitalize",
              }}
              onClick={handelUploadClick}
            >
              Upload
            </Button>
          )}
        </Typography>
        <Box></Box>
      </Paper>
    </Modal>
  );
};

AddStripeAccWallet.propTypes = {
  showModal: PropTypes.bool,
  setshowModal: PropTypes.func,
  errors: PropTypes.array,
  handelUploadClick: PropTypes.func,
  loading: PropTypes.bool,
};
