import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";

const cookies = new Cookies();
const initialState = {
  transactionHistory: null,
  IsSuccess: false,
  message: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

export const fetchTransactionHistory = createAsyncThunk(
  "WalletAccount/GetTransactionHistoryByUser",
  async (filterQuery) => {
    const response = await axiosInstance.get(
      `WalletAccount/GetTransactionHistoryByUser?userid=${cookies.get(
        "userid"
      )}&propertyname=${filterQuery?.propertyName}&date=${filterQuery?.date}`
    );

    return response.data;
  }
);

const TransactionHistorySlice = createSlice({
  name: "transactionHistory",
  initialState,
  reducers: {
    resetTransactions: (state) => {
      state.transactionHistory = null;
      state.IsSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTransactionHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransactionHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.IsSuccess = action.payload.IsSuccess;
        state.transactionHistory = action.payload.Data;
      })
      .addCase(fetchTransactionHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getTransactionHistoryDetails = (state) => state.transactionHistory;
export const { resetTransactions } = TransactionHistorySlice.actions;
export default TransactionHistorySlice.reducer;
