import {
  Box,
  Button,
  Menu,
  MenuItem,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import PropertyCard from "../../Components/PropertyCard/PropertyCard";
import { Link } from "react-router-dom";
import theme from "../../theme/index";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchAllProperties,
  fetchPropertyTypes,
  getPropertiesDetails,
} from "../../Redux/PropertySlice/PropertySlice";
import { lazy, useEffect } from "react";
import { useState } from "react";
import PropTypes from "prop-types";
import ConfirmationFavModal from "../../Components/PropertyCard/AddWishListModal";
import noDataImage from "../../Assets/nodatafound.png";

const SkeletonCard = lazy(() =>
  import("../../Components/SkeletonCard/SkeletonCard")
);

const useStyles = makeStyles(() => {
  return {
    marketplace_main: {
      width: "100% ",
      maxWidth: "1500px",
      textAlign: "left",
      display: "flex",
      flexDirection: "column",
      gap: "25px",
      padding: "50px ",
      [theme.breakpoints.down("md")]: {
        padding: "25px ",
      },
    },
    marketplace_header: {
      width: "100% !important",
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "start",
      },
    },
    add_btn_text: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    marketplace_filter_mobile: {
      display: "none",
      width: "100%",
      justifyContent: "space-between",
      gap: "1%",

      [theme.breakpoints.down("sm")]: {
        display: "flex",
      },
    },
    marketplace_filter_web: {
      width: "100%",
      backgroundColor: theme.palette.white.main,
      borderRadius: theme.borderRadius.All,
      display: "flex",
      justifyContent: "space-between",
      gap: "5%",
      padding: "10px 30px",

      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    property_serach_box: {
      color: "#FFFFFF",

      flex: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    property_serach_btn: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.white.main,
      borderRadius: theme.borderRadius.All,
      height: "fit-content !important",
      "&:hover": {
        backgroundColor: theme.palette.white.main,
        color: theme.palette.primary.main,
        border: ` 1px solid ${theme.palette.primary.main}`,
      },
    },
    btn_text: {
      color: theme.palette.white.main,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
    ul: {
      "& .MuiPaginationItem-root": {
        color: "black",
        backgroundColor: "white",
      },
      "& .MuiPagination-ul": {
        color: "black",
        backgroundColor: "white",
      },
    },
  };
});

const DashboardProperties = ({ newPropertyPath, path, color }) => {
  const {
    marketplace_main,
    marketplace_header,
    add_btn_text,
    property_serach_box,
    marketplace_filter_mobile,
    marketplace_filter_web,
    property_serach_btn,
    btn_text,
    ul,
  } = useStyles();
  const [allProperties, setallProperties] = useState([]);
  const AllProperties = useSelector(getPropertiesDetails);
  const [paginationData, setpaginationData] = useState({
    TotalPages: 0,
    PageNumber: 1,
    PageSize: 10,
  });
  const [propertyTypes, setpropertyTypes] = useState([]);

  const [fiterQuery, setfiterQuery] = useState({
    location: "",
    proprtyType: "",
    sorting: "",
    minValue: "",
    maxValue: "",
  });
  const [SelectedProperty, setSelectedProperty] = useState(null);
  const [showModal, setshowModal] = useState(false);

  const dispatch = useDispatch();
  const handelChange = (e, pageNumber) => {
    setpaginationData((prev) => {
      return (prev.PageNumber = pageNumber);
    });
    dispatch(
      fetchAllProperties({
        ...paginationData,
        PageNumber: pageNumber,
        ...fiterQuery,
      })
    );
  };
  useEffect(() => {
    dispatch(
      fetchAllProperties({
        ...paginationData,
        ...fiterQuery,
      })
    );
    dispatch(fetchPropertyTypes());
  }, [dispatch]);

  useEffect(() => {
    setallProperties(AllProperties?.properties);
    setpropertyTypes(AllProperties?.propertyTypes);
    setpaginationData({
      TotalPages: AllProperties?.TotalPages,
      PageNumber: AllProperties?.PageNumber,
      PageSize: AllProperties?.PageSize,
    });
    setfiterQuery({
      location: AllProperties?.location,
      proprtyType: AllProperties?.proprtyType,
      sorting: AllProperties?.sorting,
      minValue: AllProperties?.minValue,
      maxValue: AllProperties?.maxValue,
    });
  }, [AllProperties]);

  const handelFilterChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setfiterQuery({ ...fiterQuery, [name]: value });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      dispatch(
        fetchAllProperties({
          ...paginationData,
          PageNumber: 1,

          ...fiterQuery,
        })
      );
    }
  };

  return (
    <Box
      sx={{
        width: "100%",
        textAlign: "left",
        display: "flex",
        justifyContent: "center",
        color: color,
      }}
    >
      <Box className={marketplace_main}>
        <Box className={marketplace_header}>
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: (theme) => theme.typography.h4,
              minWidth: { md: "400px !important", sm: "0px", xs: "0px" },
              flex: 0,
              color: color,
            }}
          >
            Search Properties to Invest
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end !important",
              alignItems: "center",
              flex: 1,
              width: "100%",
            }}
          >
            <Link
              to={newPropertyPath}
              style={{ textDecoration: "none" }}
              onClick={() => {}}
            >
              <Button
                sx={{
                  display: "flex",
                  alignItems: "center",
                  backgroundColor: (theme) => theme.palette.primary.main,
                  color: (theme) => theme.palette.white.main,
                  borderRadius: (theme) => theme.borderRadius.All,
                  height: "fit-content !important",
                  "&:hover": {
                    backgroundColor: (theme) => theme.palette.white.main,
                    color: (theme) => theme.palette.primary.main,
                    border: (theme) =>
                      ` 1px solid ${theme.palette.primary.main}`,
                  },
                }}
              >
                <AddIcon />{" "}
                <Typography
                  sx={{ textTransform: "capitalize" }}
                  className={add_btn_text}
                >
                  List Property
                </Typography>
              </Button>
            </Link>
          </Box>
        </Box>
        <Box className={marketplace_filter_web}>
          <Box
            sx={{
              width: "100%",
              height: "75px",
              backgroundColor: (theme) => theme.palette.white.main,
              borderRadius: (theme) => theme.borderRadius.All,
              display: "flex",
              justifyContent: "space-between",
              gap: "20px",
              flex: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  opacity: "50%",
                }}
              >
                Location
              </Typography>
              <TextField
                size="small"
                placeholder="Search"
                variant="standard"
                name="location"
                value={fiterQuery?.location}
                onKeyPress={handleKeyPress}
                onChange={handelFilterChange}
                sx={{ width: "80%" }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  opacity: "50%",
                }}
              >
                Sort
              </Typography>
              <TextField
                size="small"
                select
                placeholder="Search"
                variant="standard"
                name="sorting"
                onKeyPress={handleKeyPress}
                value={fiterQuery?.sorting}
                onChange={handelFilterChange}
                sx={{ width: "80%" }}
              >
                <MenuItem value="">Select</MenuItem>
                <MenuItem value="LTH">Low to High</MenuItem>
                <MenuItem value="HTL">High to Low</MenuItem>
              </TextField>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  opacity: "50%",
                }}
              >
                Price
              </Typography>
              <TextField
                size="small"
                placeholder="Search"
                variant="standard"
                name="maxValue"
                onKeyPress={handleKeyPress}
                value={`${fiterQuery?.minValue} - ${fiterQuery?.maxValue}`}
                sx={{ width: "80%" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              />

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  disableRipple={true}
                  disableHoverListener={true}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                    cursor: "unset",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <TextField
                      size="small"
                      type={"number"}
                      placeholder="Min Price"
                      label="Min Price"
                      variant="outlined"
                      onKeyPress={handleKeyPress}
                      name="minValue"
                      value={fiterQuery?.minValue}
                      onChange={handelFilterChange}
                      sx={{ width: "50%" }}
                    />
                    <TextField
                      size="small"
                      type={"number"}
                      placeholder="Max Price"
                      label="Max Price"
                      variant="outlined"
                      name="maxValue"
                      onKeyPress={handleKeyPress}
                      value={fiterQuery?.maxValue}
                      onChange={handelFilterChange}
                      sx={{ width: "50%" }}
                    />
                  </Box>
                </MenuItem>
              </Menu>
            </Box>
            <Box
              sx={{
                width: "100%",
                borderRight: "1px solid black",
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                flexDirection: "column",
                gap: "5px",
              }}
            >
              <Typography
                sx={{
                  color: (theme) => theme.palette.secondary.main,
                  opacity: "50%",
                }}
              >
                Type
              </Typography>
              <TextField
                size="small"
                select
                placeholder="Search"
                variant="standard"
                name="proprtyType"
                onKeyPress={handleKeyPress}
                value={fiterQuery?.proprtyType}
                onChange={handelFilterChange}
                sx={{ width: "80%" }}
              >
                <MenuItem value="">Select</MenuItem>
                {propertyTypes?.map((Ptype, index) => {
                  return (
                    <MenuItem value={Ptype?.PropertyTypeId} key={index}>
                      {Ptype?.PropertyTypeName}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
          </Box>
          <Box className={property_serach_box}>
            <Button
              className={property_serach_btn}
              onClick={() =>
                dispatch(
                  fetchAllProperties({
                    ...paginationData,
                    PageNumber: 1,
                    ...fiterQuery,
                  })
                )
              }
            >
              <Typography className={btn_text}>Search</Typography>
            </Button>
          </Box>
        </Box>
        <Box className={marketplace_filter_mobile}>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search"
              variant="outlined"
              name="location"
              onKeyPress={handleKeyPress}
              value={fiterQuery?.location}
              onChange={handelFilterChange}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              select
              placeholder="Search"
              variant="outlined"
              name="sorting"
              value={fiterQuery?.sorting}
              onKeyPress={handleKeyPress}
              onChange={handelFilterChange}
            >
              <MenuItem value="">Select</MenuItem>
              <MenuItem value="LTH">Low to High</MenuItem>
              <MenuItem value="HTL">High to Low</MenuItem>
            </TextField>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search"
              variant="outlined"
              name="minValue"
              onKeyPress={handleKeyPress}
              value={`${fiterQuery?.minValue} - ${fiterQuery?.maxValue}`}
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            />
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                disableRipple={true}
                disableHoverListener={true}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  cursor: "unset",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                  }}
                >
                  <TextField
                    size="small"
                    type={"number"}
                    placeholder="Min Price"
                    label="Min Price"
                    variant="outlined"
                    name="minValue"
                    onKeyPress={handleKeyPress}
                    value={fiterQuery?.minValue}
                    onChange={handelFilterChange}
                    sx={{ width: "50%" }}
                  />
                  <TextField
                    size="small"
                    type={"number"}
                    placeholder="Max Price"
                    label="Max Price"
                    variant="outlined"
                    name="maxValue"
                    onKeyPress={handleKeyPress}
                    value={fiterQuery?.maxValue}
                    onChange={handelFilterChange}
                    sx={{ width: "50%" }}
                  />
                </Box>
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TextField
              fullWidth
              size="small"
              select
              placeholder="Search"
              variant="outlined"
              name="proprtyType"
              value={fiterQuery?.proprtyType}
              onKeyPress={handleKeyPress}
              onChange={handelFilterChange}
            >
              <MenuItem value="">Select</MenuItem>
              {propertyTypes?.map((Ptype, index) => {
                return (
                  <MenuItem value={Ptype?.PropertyTypeId} key={index}>
                    {Ptype?.PropertyTypeName}
                  </MenuItem>
                );
              })}
            </TextField>
          </Box>

          <Box className={property_serach_box}>
            <Button
              className={property_serach_btn}
              varient="outlined"
              onClick={() =>
                dispatch(
                  fetchAllProperties({
                    ...paginationData,
                    PageNumber: 1,

                    ...fiterQuery,
                  })
                )
              }
            >
              <Typography className={btn_text}>Search</Typography>
            </Button>
          </Box>
        </Box>
        {AllProperties?.status !== "loading" && allProperties?.length < 1 ? (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              pt: 5,
            }}
          >
            <Box component={"img"} src={noDataImage} sx={{ width: "70%" }} />
          </Box>
        ) : (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
                gap: "25px",
              }}
            >
              {AllProperties?.status !== "loading" || allProperties?.length > 0
                ? allProperties?.map((property, index) => {
                    return (
                      <PropertyCard
                        color={color}
                        property={property}
                        key={index}
                        path={path}
                        setSelectedProperty={setSelectedProperty}
                        setshowModal={setshowModal}
                        showFvrt={true}
                        isFavourite={property?.IsFavourite}
                      />
                    );
                  })
                : [...Array(10)]?.map((__, index) => {
                    return <SkeletonCard key={index} />;
                  })}
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px",
              }}
            >
              <Pagination
                count={Math.ceil(paginationData?.TotalPages)}
                variant="outlined"
                color={"primary"}
                classes={{ ul: ul }}
                onChange={AllProperties?.status !== "loading" && handelChange}
                page={paginationData?.PageNumber}
              />
            </Box>
          </>
        )}
      </Box>
      <Box>
        <ConfirmationFavModal
          showModal={showModal}
          setshowModal={setshowModal}
          setSelectedProperty={setSelectedProperty}
          SelectedProperty={SelectedProperty}
          PaginationData={{
            ...paginationData,
            ...fiterQuery,
          }}
        />
      </Box>
    </Box>
  );
};

export default DashboardProperties;
DashboardProperties.propTypes = {
  newPropertyPath: PropTypes.any.isRequired,
  path: PropTypes.any.isRequired,
  color: PropTypes.any,
};
