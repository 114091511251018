import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
import axiosInstance from "../../Utils/AxiosInstance";

const cookies = new Cookies();

const initialState = {
  currentRentBalance: 0,
  investedProperties: 0,
  tokens: [],
  totalEarnedRent: 0,
  totalPropertyValue: 0,
  accountValue: null,
  incomeChart: [],

  isSuccess: false,
  message: null,
  status: "idle",
  error: null,
};

export const fetchMyTokens = createAsyncThunk(
  "Property/GetUserTokens",
  async () => {
    const response = await axiosInstance.get(
      `Property/GetUserTokens?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

export const fetchMyAccountValue = createAsyncThunk(
  "Property/GetAccountValue",
  async () => {
    const response = await axiosInstance.get(
      `Property/GetAccountValue?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

export const fetchIncomeChartsData = createAsyncThunk(
  "Property/IncomeChart",
  async () => {
    const response = await axiosInstance.get(
      `Property/IncomeChart?userId=${cookies.get("userid")}`
    );
    return response.data;
  }
);

const TokensSlice = createSlice({
  name: "tokens",
  initialState,
  reducers: {
    removePropertyFormWishList: {
      reducer(state, action) {
        state.WishList = state.WishList.filter((property) => {
          return property.UserWishListId !== action.payload;
        });
      },
    },
    resetTokens: (state) => {
      state.currentRentBalance = 0;
      state.investedProperties = 0;
      state.tokens = [];
      state.totalEarnedRent = 0;
      state.totalPropertyValue = 0;
      state.accountValue = null;
      state.incomeChart = [];
      state.isSuccess = false;
      state.message = null;
      state.status = "idle";
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMyTokens.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMyTokens.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.currentRentBalance = action.payload.Data?.CurrentRentBalance;
        state.investedProperties = action.payload.Data?.InvestedProperties;
        state.totalEarnedRent = action.payload.Data?.TotalEarnedRent;
        state.tokens = action.payload.Data?.Tokens;
        state.totalPropertyValue = action.payload.Data?.TotalPropertyValue;
      })
      .addCase(fetchMyTokens.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchMyAccountValue.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchMyAccountValue.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.accountValue = action.payload.Data;
      })
      .addCase(fetchMyAccountValue.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchIncomeChartsData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchIncomeChartsData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.message = action.payload.Message;
        state.isSuccess = action.payload.IsSuccess;
        state.incomeChart = action.payload.Data;
      })
      .addCase(fetchIncomeChartsData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const getUserTokens = (state) => state.tokens;

export const { removePropertyFormWishList, resetTokens } = TokensSlice.actions;

export default TokensSlice.reducer;
