import { Box, Typography } from "@mui/material";
import React from "react";
import img from "../../Assets/Property/house.jpg";
import stars from "../../Assets/icons/stars.svg";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import LinearProgressWithLabel from "../LinearProgressWithLabel/LinearProgressWithLabel";
import theme from "../../theme";
import PropTypes from "prop-types";
import FavoriteIcon from "@mui/icons-material/Favorite";

const useStyles = makeStyles(() => {
  return {
    card_image: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      width: "100%",
      height: "180px",
      paddingTop: "10px",
      position: "relative",
      borderRadius: theme.borderRadius.All,
    },
  };
});

const PropertyCard = ({
  property,
  isFavourite,
  path,
  setshowModal,
  setSelectedProperty,
  UserWishListId,
  color,
  showFvrt,
}) => {
  const navigate = useNavigate();
  const { card_image } = useStyles();

  const handelCardClick = (e) => {
    console.log(`${path}/${property?.PropertyAddressId}`);
    e.stopPropagation();
    navigate(`${path}/${property?.PropertyAddressId}`, {
      state: { AddressId: property?.PropertyAddressId, color: color },
    });
  };
  const handleAddSingleFavorite = (e) => {
    e.stopPropagation();
    setSelectedProperty({
      propertyId: property?.PropertyAddressId,
      isFavourite: isFavourite,
      ActionType: `Add "${property?.AddressName}" to WishList`,
    });
    setshowModal(true);
  };

  const handleRemoveSingleFavorite = (e) => {
    e.stopPropagation();
    setSelectedProperty({
      UserWishListId: [UserWishListId],
      ActionType: `Remove "${property?.AddressName}" from WishList`,
      isFavourite: isFavourite,
    });
    setshowModal(true);
  };

  let percentage =
    Math.round(
      (Number(property?.TokenSold) / Number(property?.TokenSupply)) * 100 * 100
    ) / 100;

  return (
    <Box
      sx={{
        zIndex: (theme) => theme.zIndex.propertyCard,
        backgroundColor: (theme) => theme.palette.white.main,
        borderRadius: (theme) => theme.borderRadius.All,
        border: (theme) => `1px solid ${theme.palette.grey.A400}`,

        width: "330px !important",
        height: "410px",
        cursor: "pointer",
      }}
      onClick={handelCardClick}
    >
      <Box
        className={card_image}
        sx={{
          backgroundImage: `url(${
            property?.PropertyImages && property?.PropertyImages.length > 0
              ? property?.PropertyImages[0]?.Link
              : img
          })`,
        }}
      >
        {property?.Popular ? (
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.primary.main,

              width: "fit-content",
              marginTop: "-30px",
              zIndex: "99",
              position: "absolute",
              bottom: -15,
              left: -15,
              borderRadius: "8px 8px 8px 4px",
              paddingX: "20px",
              display: "flex",
              gap: "10px",
              color: (theme) => theme.palette.white.main,
            }}
          >
            <img src={stars} alt="" loading="lazy" /> Popular
            <Box
              sx={{
                backgroundColor: (theme) => theme.palette.primary.main,
                width: "15px",
                height: "10px",
                marginTop: "-30px",
                position: "absolute",
                bottom: -5,
                left: 0,
                borderRadius: "0px 0px 0px 100%",
              }}
            ></Box>
          </Box>
        ) : null}
      </Box>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // minHeight: "200px",
          height: "calc(410px - 180px)",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box sx={{ textAlign: "left" }}>
              <Typography
                sx={{
                  fontSize: (theme) => theme.typography.cardTitle,
                  color: (theme) => theme.palette.primary.main,
                }}
              >
                ${property?.Value?.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  fontWeight: (theme) => theme.typography.cardTitle,
                  lineHeight: "24px",
                }}
              >
                {property?.PropertyName}
              </Typography>
            </Box>
            <Box>
              {showFvrt ? (
                <Box
                  sx={{
                    height: "40px !important",
                    width: "40px !important",
                    borderRadius: "50%",
                    border: (theme) =>
                      `1px solid ${theme.palette.greenShades.g92}`,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  {isFavourite ? (
                    <FavoriteIcon
                      onClick={handleRemoveSingleFavorite}
                      sx={{
                        color: "red",
                        cursor: "pointer",
                      }}
                    />
                  ) : (
                    <FavoriteBorderIcon
                      onClick={handleAddSingleFavorite}
                      sx={{
                        color: (theme) => theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                    />
                  )}
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2,
                lineHeight: "30px",
                color: (theme) => theme.palette.secondary.main,
                opacity: "50%",
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              {property?.AddressName?.slice(0, 300)}
              {property?.AddressName.length > 300 ? "..." : ""}
            </Typography>
          </Box>
        </Box>

        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle1,

                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {property?.TotalReturn?.toFixed(2)}% IRR
            </Typography>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle1,

                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {property?.CapRate?.toFixed(2)}% CoC
            </Typography>
          </Box>

          <LinearProgressWithLabel
            value={
              property?.TokenSupply ? (percentage > 100 ? 100 : percentage) : 0
            }
          />

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2,

                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {property?.TokenSupply
                ? percentage > 100
                  ? 100
                  : percentage
                : 0}
              % share sold
            </Typography>
            <Typography
              sx={{
                fontWeight: (theme) => theme.typography.subtitle2,

                color: (theme) => theme.palette.secondary.main,
              }}
            >
              {property?.TokenSupply
                ? Number(property?.TokenSupply) - Number(property?.TokenSold)
                : 0}{" "}
              Token available
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertyCard;

PropertyCard.propTypes = {
  property: PropTypes.any.isRequired,
  isFavourite: PropTypes.bool,
  path: PropTypes.string,
  setshowModal: PropTypes.func,
  setSelectedProperty: PropTypes.func,
  UserWishListId: PropTypes.any,
  color: PropTypes.any,
  showFvrt: PropTypes.bool,
};
