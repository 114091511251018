import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { lazy, Suspense, useEffect, useState } from "react";
import PropTypes from "prop-types";
import FallBackLoading from "../../Components/FallbackLoading/FallBackLoading";
import Cookies from "universal-cookie";
// import { Navigate } from "react-router-dom";
// import { Navigate } from "react-router-dom";
const BasicTopBar = lazy(() =>
  import("../../Components/TopBar/BasicTopBar/BasicTopBar")
);
const Footer = lazy(() => import("../../Components/Footer/Footer"));

const MainLayout = ({ activePage }) => {
  const cookies = new Cookies();
  const [open, setOpen] = useState(false);
  const [OnHover, setOnHover] = useState(false);
  const [isLogedIn, setisLogedIn] = useState(false);
  const [userPath, setuserPath] = useState("/dashboard");

  useEffect(() => {
    if (cookies.get("token") && cookies.get("userid")) {
      setisLogedIn(true);
      if (cookies.get("userType") !== "user") {
        // setuserPath("/admin/dashboard");
        setuserPath("/admin/marketplace");
      }
      if (cookies.get("userType") === "user") {
        setuserPath("/dashboard");
      }
    }
  }, []);
  // const path = useMemo(() => {
  //   if (localStorage.getItem("activepath")) {
  //     return localStorage.getItem("activepath");
  //   } else {
  //     return null;
  //   }
  // }, []);
  // if (path) return <Navigate to={path} replace />;

  return (
    <Suspense fallback={<FallBackLoading />}>
      {/* {localStorage.getItem("activepath") && (
        <Navigate to={localStorage.getItem("activepath")} replace />
      )} */}
      <Box sx={{ display: "flex" }}>
        <CssBaseline />

        {/* Top Bar Component  */}

        <Box sx={{ flex: 1 }}>
          <BasicTopBar
            open={open}
            setOpen={setOpen}
            isLogedIn={isLogedIn}
            path={userPath}
          />

          {/* MainLayout Component  */}

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              // backgroundColor: (theme) => theme.palette.background.paper,
              minHeight: "calc(100vh - 115px)",
              maxHeight: "fit-content ",
              paddingTop: "50px",
              backgroundColor: "black ",
              borderBottom: (theme) =>
                `1px solid ${theme.palette.primary.main}`,
            }}
            onMouseOver={() => {
              if (OnHover === true) {
                setOpen(false);
                setOnHover(false);
              }
            }}
          >
            <Box>{activePage}</Box>
          </Box>
          <Footer />
        </Box>
      </Box>
    </Suspense>
  );
};
export default MainLayout;

MainLayout.propTypes = {
  activePage: PropTypes.any,
};
